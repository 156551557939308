import moment from "moment";
import { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { Panel } from "rsuite";
import { debounce } from "lodash";
import update from "immutability-helper";
import { OfferSearchTabs, TABS, TAB_KEY } from "./offer-search-tabs";
import { OfferSearchResults } from "./offer-search-results";
import "./offer-search-form.less";
import { OrderContext } from "../helper/order-context";
import { isAllDayEvent } from "../helper/events-utilities";
import { SORT_OPTIONS } from "../constants/index";

const tabsWithDefaultOccupancy = [TAB_KEY.ROOM, TAB_KEY.FERRY];
const tabsWithRadius = [TAB_KEY.ROOM, TAB_KEY.SERVICE];

const getDefaultFilters = (tab, { cities }) => {
  return {
    ...(tabsWithRadius.includes(tab) && { radius: "20" }),
    ...(TAB_KEY.SERVICE === tab && cities?.[0] && { city: cities[0] }),
    sort: SORT_OPTIONS[0].value,
  };
};

export const OfferSearchForm = ({
  firstDate,
  participants,
  onResultSelection,
  selectedSearchItem,
  clearSelectedItem,
  onResultSearch,
}) => {
  const { showErrorMessage, client, orderItems, order } = useContext(OrderContext);
  const cities = useMemo(
    () =>
      orderItems
        .filter((item) => isAllDayEvent(item?.fromDate, item?.toDate))
        .filter((item) => {
          const start = moment(item.fromDate).startOf("day");
          const end = moment(item.toDate ?? 8.64e11).startOf("day");
          const isSameOrAfter =
            firstDate && firstDate.isSameOrAfter(start, "day");
          const isBefore = firstDate && firstDate.isBefore(end, "day");
          return isSameOrAfter && isBefore;
        })
        .map((item) => item?.product?.city)
        .filter(Boolean),
    [orderItems, firstDate]
  );

  useEffect(() => {
    if (TAB_KEY.SERVICE === activeSearchTab) {
      setFilter("city", cities[0]);
    }
  }, [cities, activeSearchTab, setFilter]);
  const [activeSearchTab, setActiveSearchTab] = useState(TAB_KEY.ROOM);
  const [isLoading, setIsLoading] = useState(true);

  const [searchFreeText, setSearchFreeText] = useState("");
  const [filters, setFilters] = useState(
    getDefaultFilters(activeSearchTab, { cities })
  );

  const [searchResults, setSearchResults] = useState([]);
  const [isDefaultOccupancy, setIsDefaultOccupancy] = useState(true);

  const setFilter = useCallback(
    (filterKey, filterValue) => {
      if (filterValue != null) {
        setFilters(update(filters, { [filterKey]: { $set: filterValue } }));
      } else {
        setFilters(update(filters, { $unset: [filterKey] }));
      }
    },
    [filters]
  );

  const switchTab = useCallback(
    (newActive) => {
      setFilters(getDefaultFilters(newActive, { cities }));
      setActiveSearchTab(newActive);
      clearSelectedItem();
      setSearchFreeText("");
    },
    [cities]
  );

  const searchItems = useCallback(
    debounce((tab, searchText, filtersObj) => {
      const url = `offer-search/${tab}/${order.id}`;

      const params = new URLSearchParams();

      if (participants) {
        params.set("amount", participants);
      }
      params.set("date", (firstDate || moment()).format("YYYY-MM-DD"));
      const conditions = { ...filtersObj };

      if (searchText !== "") {
        conditions.search = searchText;
      }

      if (tabsWithDefaultOccupancy.includes(tab)) {
        conditions.defaultOccupancyTypes = isDefaultOccupancy;
      }

      params.set("conditions", JSON.stringify(conditions));

      setSearchResults([]);
      setIsLoading(true);
      clearSelectedItem();

      client.get(`${url}?${params.toString()}`).then(
        (items) => {
          setSearchResults(items);
          setIsLoading(false);
        },
        (err) =>
          showErrorMessage(err?.data?.localizedMessage ?? "Items search error")
      );
    }, 500),
    [firstDate, isDefaultOccupancy, participants]
  );

  const onSelectResult = (item) => {
    onResultSelection(item);
  };

  useEffect(() => {
    onResultSearch(searchResults);
  }, [searchResults]);

  useEffect(() => {
    searchItems(activeSearchTab, searchFreeText, filters);
  }, [activeSearchTab, searchFreeText, filters, firstDate, isDefaultOccupancy]);

  const OfferSearchOptions = useMemo(() => TABS[activeSearchTab].component, [
    activeSearchTab,
  ]);
  const sortOptions = SORT_OPTIONS.filter(
    ({ value }) => !(TAB_KEY.ROOM !== activeSearchTab && value === "category")
  );
  return (
    <Panel bordered className="offer-search-form__container">
      <div className="offer-sidebar">
        <OfferSearchTabs
          activeSearchTab={activeSearchTab}
          switchTab={switchTab}
        />
        <div className="row offer-search-form-options__container">
          <OfferSearchOptions
            filters={filters}
            sortOptions={sortOptions}
            searchFreeText={searchFreeText}
            setSearchFreeText={setSearchFreeText}
            activeSearchTab={activeSearchTab}
            setFilter={setFilter}
            isDefaultOccupancy={isDefaultOccupancy}
            setIsDefaultOccupancy={setIsDefaultOccupancy}
          />
        </div>
        <OfferSearchResults
          isLoading={isLoading}
          selectedSearchItem={selectedSearchItem}
          searchResults={searchResults}
          onSelectResult={onSelectResult}
        />
      </div>
    </Panel>
  );
};
